var landscapeImageWidth = 0.6; // In percent * 100
var mediumBreakpoint = 1000;

$('.thumbnail').find('a').hover(hoverImage, hoverOutImage);
$('a.thumbnail').hover(hoverImage, hoverOutImage);

var $currentImage = null;

function hoverImage() {
    if (window.innerWidth < mediumBreakpoint) {
        return;
    }

    var $this = $(this);
    var $thumnail = $this.hasClass('thumbnail') ? $this : $this.parents('.thumbnail');
    var $img = $thumnail.find('.image');

    if ($img.length === 0) {
        var src = $thumnail.data('thumbnail');

        if (src) {
            if ($thumnail.hasClass('project')) {
                $thumnail.find('.title').append('<div class="image"><div><img src="' + src + '" /></div></div>');
            } else {
                $thumnail.append('<div class="image hide slim transition"><div><img src="' + src + '" /></div></div>');
            }

            $img = $thumnail.find('.image');

            if ($this.hasClass('thumbnail-static')) {
                $img.removeClass('hide').removeClass('slim');
            } else {
                $img.find('img').one('load', function () {
                    $img.removeClass('hide');

                    setTimeout(function () {
                        $img.removeClass('slim');
                    }, 10);
                });
            }
        }
    } else {
        $img.addClass('transition').removeClass('hide');
        setTimeout(function () {
            $img.removeClass('hide');
            setTimeout(function () {
                $img.removeClass('slim');
            }, 10);
        });
    }

    if ($this.hasClass('thumbnail-static')) {
        var $parentDiv = $img.parents('div');
        var $parentWrapper = $img.parents('.projects-list').find('> div');
        var rowHeight = $parentDiv.height();
        var rowOffset = $parentDiv.offset().top - $parentWrapper.offset().top;

        var css = {
            top: (rowOffset + rowHeight / 2) + 'px'
        };

        $img.css(css);
    } else {
        $currentImage = $img;
        $img.css('position', 'fixed');
    }
}

function hoverOutImage() {
    if (window.innerWidth < mediumBreakpoint) {
        return;
    }

    var $this = $(this);
    var $thumbnail = $this.hasClass('thumbnail') ? $this : $this.parents('.thumbnail');
    var $img = $thumbnail.find('.image');
    $img.removeClass('transition');
    setTimeout(function () {
        $img.addClass('hide').addClass('slim');
    });

    $currentImage = null;
}

document.onmousemove = function (event) {
    event = event || window.event; // IE-ism

    if ($currentImage) {
        $currentImage.css({
            top: event.clientY + 10,
            left: event.clientX + 10
        });
    }
};