var mediumBreakpoint = 1000;

$('.image-text a').hover(function() {
    if (window.innerWidth < mediumBreakpoint) {
        return;
    }

    var weights = [300, 400, 500];
    var randomWeight = weights[Math.floor(Math.random() * weights.length)];
    $(this).find('.project-title').css('font-weight', randomWeight);
});