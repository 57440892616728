if ('IntersectionObserver' in window) {
    var options = {
        root: null, // relative to document viewport
        rootMargin: '0px 0px 400px', // margin around root. Values are similar to css property. Unitless values not allowed
        threshold: 0 // visible amount of item shown in relation to root
    };

    var onIntersectionChange = function (changes, observer) {
        changes.forEach(change => {
            if (change.intersectionRatio > 0) {
                loadTarget(change.target);
            }
        });
    };

    var observer = new IntersectionObserver(onIntersectionChange, options);

    var images = document.querySelectorAll('img.lazy');
    images.forEach(img => observer.observe(img));
} else {
    var lazyImages = document.getElementsByClassName('lazy');
    for (var i = 0; i < lazyImages.length; i++) {
        var img = lazyImages[i];
        loadTarget(img);
    }
}

function loadTarget(target) {
    if (!target.src) {
        target.src = target.getAttribute('data-src');

        if (target.getAttribute('data-srcset')) {
            target.srcset = target.getAttribute('data-srcset');
        }

        target.onload = function () {
            target.className += ' show';
        }
    } else {
        target.className += ' show';
    }
}