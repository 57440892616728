import {gsap} from "./gsap/gsap-core";
import {MorphSVGPlugin} from "./gsap/MorphSVGPlugin";

gsap.registerPlugin(MorphSVGPlugin);

document.addEventListener("DOMContentLoaded", function () {
    var animationTime = 1.5;
    var animationDelay = 0.5;

    MorphSVGPlugin.convertToPath('polygon');

    // Animate H
    gsap.to("#h-thin", {duration: 0, delay: animationDelay, morphSVG: "#h-thick"});
    gsap.to("#h-thin", {duration: animationTime, delay: animationDelay, morphSVG: "#h-thin"});
    gsap.to("#h-thin-white", {duration: 0, delay: animationDelay, morphSVG: "#h-thick-white"});
    gsap.to("#h-thin-white", {duration: animationTime, delay: animationDelay, morphSVG: "#h-thin-white"});

    // Animate O
    gsap.to("#o-thin", {duration: 0, delay: animationTime + animationDelay * 2, morphSVG: "#o-thick"});
    gsap.to("#o-thin", {duration: animationTime, delay: animationTime + animationDelay * 2, morphSVG: "#o-thin"});
    gsap.to("#o-thin-white", {duration: 0, delay: animationTime + animationDelay * 2, morphSVG: "#o-thick-white"});
    gsap.to("#o-thin-white", {duration: animationTime, delay: animationTime + animationDelay * 2,morphSVG: "#o-thin-white"});

    // Animate L
    gsap.to("#l-thin", {duration: 0, delay: animationTime * 2 + animationDelay * 3, morphSVG: "#l-thick"});
    gsap.to("#l-thin", {duration: animationTime, delay: animationTime * 2 + animationDelay * 3, morphSVG: "#l-thin"});
    gsap.to("#l-thin-white", {duration: 0, delay: animationTime * 2 + animationDelay * 3, morphSVG: "#l-thick-white"});
    gsap.to("#l-thin-white", {duration: animationTime, delay: animationTime * 2 + animationDelay * 3, morphSVG: "#l-thin-white"});

    // Animate O
    gsap.to("#o-thin", {duration: 0, delay: animationTime * 3 + animationDelay * 4, morphSVG: "#o-thick"});
    gsap.to("#o-thin", {duration: animationTime, delay: animationTime * 3 + animationDelay * 4, morphSVG: "#o-thin"});
    gsap.to("#o-thin-white", {duration: 0, delay: animationTime * 3 + animationDelay * 4, morphSVG: "#o-thick-white"});
    gsap.to("#o-thin-white", {duration: animationTime, delay: animationTime * 3 + animationDelay * 4, morphSVG: "#o-thin-white"});

    // Animate N
    gsap.to("#n-thin", {duration: 0, delay: animationTime * 4 + animationDelay * 5, morphSVG: "#n-thick"});
    gsap.to("#n-thin", {duration: animationTime, delay: animationTime * 4 + animationDelay * 5, morphSVG: "#n-thin"});
    gsap.to("#n-thin-white", {duration: 0, delay: animationTime * 4 + animationDelay * 5, morphSVG: "#n-thick-white"});
    gsap.to("#n-thin-white", {duration: animationTime, delay: animationTime * 4 + animationDelay * 5, morphSVG: "#n-thin-white"});

    var $holonLogo = $('.holon-logo');
    $holonLogo.hover(function() {
        gsap.timeScale(3);
    }, function () {
        gsap.timeScale(1);
    })
});