var mobileHeaderHeight = 95; // in px
var headerHeight = 181; // in px
var $header = $('header');
var $headerDiv = $header.find(' > div');
var headerDiv = $headerDiv[0];

var mediumBreakpoint = 1000;

$(window).scroll(function() {
    if ($headerDiv.hasClass('hover')) {
        return;
    }

    var $this = $(this);
    headerDiv.style.transform = 'translateY(-' + Math.min($this.scrollTop(), headerHeight) + 'px)';
});

$header.hover(function() {
    if (window.innerWidth < mediumBreakpoint) {
        return;
    }

    $headerDiv.addClass('hover');
    headerDiv.style.transition = 'transform 200ms ease-in-out';
    headerDiv.style.transform = '';
}, function() {
    if (window.innerWidth < mediumBreakpoint) {
        return;
    }

    $headerDiv.removeClass('hover');
    headerDiv.style.transform = 'translateY(-' + Math.min($(window).scrollTop(), headerHeight) + 'px)';

    setTimeout(function() {
        headerDiv.style.transition = '';
    }, 200)
});