var longAnimationLength = 1000;
var $projectInfo;

var timeout = null;
$('.more-info').click(function(e) {
    e.preventDefault();

    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }

    $projectInfo = $(this).parents('.post-type-project').find('.project-info');
    $projectInfo.removeClass('full-hide');

    setTimeout(function() {
        $projectInfo.addClass('transition');
        $projectInfo.removeClass('hide');
    }, 10);
});

$('.close-project-info').find('a').click(function(e) {
    e.preventDefault();
    $(this).parents('.project-info').addClass('hide');
    timeout = setTimeout(function () {
        $projectInfo.addClass('full-hide');
        timeout = null;
    }, longAnimationLength);
});