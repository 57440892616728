
var disciplineFilter = null;
var locationFilter = null;

$('.people .filter > a').click(function(e) {
    e.preventDefault();

    var $this = $(this);
    var $parent = $this.parents('.filter');
    var $ul = $this.siblings('ul');

    $parent.toggleClass('open');
    $ul.slideToggle();
});

$('.people .filter ul a').click(function(e) {
    e.preventDefault();

    var $this = $(this);
    var $parent = $this.parents('.filter');
    var filter = $this.data('filter');
    var value = $this.data('value');
    var $ul = $this.parents('ul');

    $ul.find('a').removeClass('active');
    $this.addClass('active');

    switch (filter) {
        case 'location':
            locationFilter = value;
            break;
        case 'discipline':
            disciplineFilter = value;
            break;
    }

    filterPeople();

    $parent.removeClass('open');
    $ul.slideUp();
});

function filterPeople() {

    var matcher = disciplineFilter ? '.' + disciplineFilter : '';
    matcher += locationFilter ? '.' + locationFilter : '';

    var $allPeople = $('.people .person');
    $allPeople.addClass('hide');

    if (matcher) {
        $('.people .person' + matcher).removeClass('hide');
    } else {
        $allPeople.removeClass('hide');
    }

    var $people = $('.person:not(.hide)');
    $people.removeClass('sm-last').removeClass('md-last').removeClass('lg-last');

    $people.each(function(index, person) {
        var $person = $(person);

        if (index % 2 === 1) {
            $person.addClass('sm-last');
        }

        if (index % 3 === 2) {
            $person.addClass('md-last');
        }

        if (index % 4 === 3) {
            $person.addClass('lg-last');
        }
    })
}