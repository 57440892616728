var defaultAnimationLength = 200;
var animationDelay = 250;
var closeAnimationLength = 500 + animationDelay;
var $holonLogo = $('.holon-logo');

$('.toggle-menu').click(function(e) {
    e.preventDefault();

    var $nav = $('nav');
    $nav.toggleClass('show-menu');

    var $menuIcon = $('.menu-icon');
    if ($menuIcon.hasClass('open')) {
        $menuIcon.removeClass('open').addClass('closed');
    } else {
        $menuIcon.addClass('open').removeClass('closed');
        $holonLogo.addClass('move-back');
    }

    setTimeout(function() {
        $nav.toggleClass('show-menu-transition');

        if (!$menuIcon.hasClass('open')) {
            $holonLogo.removeClass('move-back');
        }
    }, closeAnimationLength)
});