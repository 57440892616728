var smallBreakpoint = 600;
var $filterViewOptions = $('.filter-view').find('a');

$filterViewOptions.click(function (e) {
    e.preventDefault();

    var $this = $(this);

    $filterViewOptions.removeClass('active');
    $this.addClass('active');

    var view = $this.data('view');
    var $projectsImages = $('.projects-images');
    var $projectsList = $('.projects-list');

    switch (view) {
        case 'images':
            $projectsImages.removeClass('hide');
            $projectsList.addClass('hide');
            break;
        case 'list':
            $projectsImages.addClass('hide');
            $projectsList.removeClass('hide');
            break;
    }
});

$('.filter-project-types .mobile-filter-toggle').click(function(e) {
    e.preventDefault();

    $('.filter-project-types .project-types').slideToggle();
});

var $filterProjectTypeOptions = $('.filter-project-types').find('li a');
var $projects = $('.projects').find('.project');

$filterProjectTypeOptions.click(function (e) {
    e.preventDefault();

    var $this = $(this);

    $filterProjectTypeOptions.removeClass('active');
    $this.addClass('active');

    var projectType = $this.data('type');
    switch (projectType) {
        case 'all':
            $projects.removeClass('hide');
            break;
        default:
            $projects.addClass('hide');
            $('.project.' + projectType).removeClass('hide');
            break;
    }

    $projects.removeClass('portrait').removeClass('landscape').removeClass('pull-up');

    var $visibleProjects = $('.projects-images .project:not(.hide)');
    for (var i = 0; i < $visibleProjects.length; i++) {
        var $project = $($visibleProjects[i]);

        var $imageText = $project.find('.image-text');

        $imageText.removeClass('left').removeClass('right').removeClass('portrait').removeClass('landscape');

        if (i % 2 === 1) {
            $imageText.addClass('left');
        } else {
            $imageText.addClass('right');
        }

        var $image = $imageText.find('img');
        var newImageSrc = null;
        if (i % 3 === 0) {
            $project.addClass('landscape');
            $imageText.addClass('landscape');
            newImageSrc = $image.data('landscapeImage');
        } else {
            $project.addClass('portrait');
            $imageText.addClass('portrait');
            newImageSrc = $image.data('portraitImage');
        }

        if ($image.length > 0 && newImageSrc && $project.parents('.projects-images').length > 0) {
            $image[0].src = newImageSrc;
        }

        if (i % 3 === 2) {
            $project.addClass('pull-up');
        }
    }

    $('.project.header').removeClass('hide');

    if (window.innerWidth < smallBreakpoint) {
        $('.filter-project-types .project-types').slideToggle();
    }
});