import animateScrollTo from  './animateScrollTo'

$('.to-top a').click(function(e) {
    e.preventDefault();

    animateScrollTo(
        document.body,
        700,
        'easeInOutCubic'
    );
})